const AppPaths = {
	
	/**
	 * @property {String} Api url
	 */
	
	apiURL        : 'https://api.hotelnirvana.com',
	
	/**
	 * Homepage path generator
	 * @return {"/inicio"}
	 */
	
	home          : () => '/inicio',
	
	/**
	 * Facilities path generator
	 * @return {"/instalaciones"}
	 */
	
	facilities    : () => '/instalaciones',
	
	/**
	 * Recreation path generator
	 * @param {Number} id
	 * @return {"/recreacion"}
	 */
	
	recreation    : () => '/recreacion',
	
	/**
	 * Gastronomy path generator
	 * @return {"/gastronomia"}
	 */
	
	gastronomy     : () => '/gastronomia',
	
	/**
	 * Restaurant menu path generator
	 * @return {"https://www.hotelnirvana.com"}
	 */
	
	restaurantMenu : () => 'https://www.hotelnirvana.com',
	
	/**
	 * Services path generator
	 * @return {"/servicios"}
	 */
	
	services       : () => '/servicios',
	
	/**
	 * Activities JSON path generator
	 * @return {"/api/recreation.json?limit=${limit}"}
	 */
	
	activitiesJSON : ({ limit }) => `${AppPaths.apiURL}/api/recreation.json${limit ? `?limit=${limit}` : ''}`,
	
	/**
	 * Rides JSON path generator
	 * @return {"/rides.json"}
	 */
	
	ridesJSON     : () => `${AppPaths.apiURL}/api/rides.json`
	
};

export default AppPaths;