import React from 'react';

/**
 * Loader component
 * @return {JSX.Element}
 * @constructor
 */

function Loader({ text = 'Cargando...', type = '' }) {
	return (
		<div className={`loader ${type === 'inline' ? 'loader--inline' : ''}`}>
			{type !== 'inline' && <div className="loader__opaque"></div>}
			<div className="loader__content">
				<div className="loader__spinner"></div>
				<div className="loader__text">{text}</div>
			</div>
		</div>
	);
}

export default Loader;